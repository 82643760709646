import { COUNTRIES } from "./Settings";

// Theme
export const THEME_SET = "THEME_SET";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

// County, State, City
export const FETCH_COUNTRY_REQUEST = "FETCH_COUNTRY_REQUEST";
export const FETCH_COUNTRY_SUCCESS = "FETCH_COUNTRY_SUCCESS";
export const FETCH_COUNTRY_FAILURE = "FETCH_COUNTRY_FAILURE";

export const FETCH_STATE_REQUEST = "FETCH_STATE_REQUEST";
export const FETCH_STATE_SUCCESS = "FETCH_STATE_SUCCESS";
export const FETCH_STATE_FAILURE = "FETCH_STATE_FAILURE";

export const FETCH_CITY_REQUEST = "FETCH_CITY_REQUEST";
export const FETCH_CITY_SUCCESS = "FETCH_CITY_SUCCESS";
export const FETCH_CITY_FAILURE = "FETCH_CITY_FAILURE";

// Person NamePrefix and NameSufix 
export const FETCH_NAMEPREFIX_REQUEST = "FETCH_NAMEPREFIX_REQUEST";
export const FETCH_NAMEPREFIX_SUCCESS = "FETCH_NAMEPREFIX_SUCCESS";
export const FETCH_NAMEPREFIX_FAILURE = "FETCH_NAMEPREFIX_FAILURE";

export const FETCH_NAMESUFFIX_REQUEST = "FETCH_NAMESUFFIX_REQUEST";
export const FETCH_NAMESUFFIX_SUCCESS = "FETCH_NAMESUFFIX_SUCCESS";
export const FETCH_NAMESUFFIX_FAILURE = "FETCH_NAMESUFFIX_FAILURE";

export { COUNTRIES };
