const COUNTRIES = [
    {
      label: "United States",
      value: "US"
    },
    {
      label: "United Kingdom",
      value: "UK"
    },
    {
      label: "Canada",
      value: "CA"
    },
    {
      label: "Australia",
      value: "AU"
    }
]

export {
    COUNTRIES
}